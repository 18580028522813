import React, { useState } from "react";
import logo from "../assets/knackbay_logo.png";
import { FaXmark, FaBars } from "react-icons/fa6";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { MdCall, MdLocationOn } from "react-icons/md";
import { IoMdClose, IoMdMail } from "react-icons/io";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";

import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenContact = () => {
    setIsDialogOpen(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItem = [
    { link: "Home", path: "home" },
    { link: "About Us", path: "about" },
    { link: "Services", path: "services" },
    { link: "Contact Us", path: "Contact" },
    { link: "Our Products", path: "Product" },
  ];

  return (
    <>
      <nav className="bg-white opacity-100 md:px-14 shadow-custom p-4 max-w-screen-2xl mx-auto text-blue fixed top-0 right-0 left-0">
        <div className="text-lg container mx-auto font-medium">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="" className="w-24" />
            </Link>
            <div className="md:flex hidden">
              <ul className="flex space-x-28 ml-auto mr-10 font-quicksand font-semibold">
                {navItem.map(({ link, path }) => (
                  <li key={path} className="inline-block">
                    <ScrollLink
                      spy={true}
                      smooth={true}
                      offset={-100}
                      to={path}
                      onClick={() => {
                        if (path === "Contact") {
                          handleOpenContact();
                        }
                      }}
                      className="hover:text-gray-300 cursor-pointer"
                    >
                      {link}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none focus:text-gray"
              >
                {isMenuOpen ? (
                  <FaXmark className="w-6 h-6 text-blue" />
                ) : (
                  <FaBars className="w-6 h-6 text-blue" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Dialog Section */}
      <div
        className={`fixed inset-0 w-full h-full flex items-center justify-center z-50 ${
          isDialogOpen ? "block" : "hidden"
        } pr-[30px] sm:pr-0`}
      >
        <div className="bg-lightgray mt-24 p-4 w-full md:w-[60%] rounded-md shadow-md relative z-10">
          <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
            <div className="flex flex-row items-center justify-between">
              <img src={logo} alt="" className="w-20" />
              <h3 className="text-primary font-bold mt-5">Contact Us</h3>
              <IoMdClose
                className="text-black cursor-pointer mt-5 mr-5"
                onClick={handleCloseDialog}
              />
            </div>

            <div className="p-6 flex flex-col md:flex-row md:justify-between">
              {/* Email and Contact Number */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                    <IoMdMail className="text-white" size={28} />
                  </span>
                  <div className="ml-4">
                    <h4 className="font-bold">Email:</h4>
                    <h4 className="text-para">knackbay@gmail.com</h4>
                  </div>
                </div>

                <div className="flex items-center">
                  <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                    <MdCall className="text-white" size={28} />
                  </span>
                  <div className="ml-4">
                    <h4 className="font-bold">Contact Number:</h4>
                    <h4 className="text-para">+91 9025243272</h4>
                  </div>
                </div>
              </div>

              {/* Address Section */}
              <div className="flex items-center md:ml-10 mt-6 md:mt-0">
                <span className="bg-blue w-13 h-13 flex items-center justify-center rounded-full p-2">
                  <MdLocationOn className="text-white" size={28} />
                </span>
                <div className="ml-4">
                  <h4 className="font-bold">Address:</h4>
                  <ul className="mt-2 space-y-1">
                    <li className="text-sm md:text-base whitespace-normal md:whitespace-nowrap">
                      620/6, 1st Floor, Hari Tyre Building,
                      <h3>Katpadi, Vellore, Tamil Nadu</h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Follow Us Section */}
            <div className="mx-auto py-6">
              <h4 className="text-center text-lg font-bold">Follow Us</h4>
              <div className="flex justify-center items-center text-blue space-x-4 mt-2">
                <Link to="https://www.linkedin.com/company/knackbay/mycompany/">
                  <FaLinkedin size={30} />
                </Link>
                <Link to="https://www.instagram.com/knackbay_/">
                  <FaInstagramSquare size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`space-y-4 px-4 pt-28 mb-5 bg-blue-light ${
          isMenuOpen ? "block fixed top-4 right-0 left-0" : "hidden"
        }`}
      >
        {navItem.map(({ link, path }) => (
          <Link
            key={path}
            spy={true}
            smooth={true}
            offset={-100}
            to={path}
            onClick={() => {
              if (path === "Contact") {
                handleOpenContact();
              }
              toggleMenu(); // Close menu after selection
            }}
            className="block hover:text-gray cursor-pointer pl-16"
          >
            {link}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Navbar;
